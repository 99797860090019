define("personal-portfolio/routes/posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PostsRoute = (_class = class PostsRoute extends Ember.Route {
    model() {
      return this.store.findAll('post');
    }

    loading() {
      return true;
    }

    error(error) {
      this.replaceWith('/');
      return true; // if (error.status === '403') {
      //   this.replaceWith('/');
      // } else {
      //   this.notifier.error(error);
      //   // Let the route above this handle the error.
      //   return true;
      // }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loading", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class);
  _exports.default = PostsRoute;
});