define("personal-portfolio/adapters/projects/index", ["exports", "personal-portfolio/adapters/application", "personal-portfolio/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ProjectsIndexAdapter extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", 'https://api.github.com');
    }

    urlForFindAll() {
      let baseUrl = this.host + `/users/${_environment.default.USERNAME}/repos`;
      return baseUrl;
    }

  }

  _exports.default = ProjectsIndexAdapter;
});