define("personal-portfolio/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xf35/06M",
    "block": "{\"symbols\":[\"project\",\"@model\"],\"statements\":[[7,\"main\",true],[8],[0,\"\\n  \"],[7,\"article\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"Welcome to my personal portfolio.\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"If you are wondering why this is empty, its because this website is dedicated to building out our personal portfolio website as a series of tutorials to show us how to built out this site in Ember JS.\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"If you look at this projects \"],[7,\"a\",true],[10,\"href\",\"https://github.com/berdjabrahamian/Personal-Website-EmberJS\"],[8],[0,\"GitHub Public\\n      Repository\"],[9],[0,\", the README will explain it all\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"If you want to get started and see this project come together, go to the \"],[5,\"link-to\",[],[[\"@route\"],[\"lessons\"]],{\"statements\":[[0,\"Lessons\"]],\"parameters\":[]}],[0,\" page.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[1,[23,1,[\"id\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "personal-portfolio/templates/index.hbs"
    }
  });

  _exports.default = _default;
});