define("personal-portfolio/templates/projects/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ort/LkpJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"article\",true],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[1,[24,[\"model\",\"name\"]],false],[9],[0,\"\\n  \"],[7,\"h5\",true],[8],[0,\"id:\"],[1,[24,[\"model\",\"id\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"fork\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h6\",true],[8],[0,\"This is a fork\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"section\",true],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Description: \"],[1,[24,[\"model\",\"description\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Created At: \"],[1,[24,[\"model\",\"created_at\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Last Updated: \"],[1,[24,[\"model\",\"updated_at\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Clone: \"],[1,[24,[\"model\",\"clone_url\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Language: \"],[1,[24,[\"model\",\"language\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "personal-portfolio/templates/projects/show.hbs"
    }
  });

  _exports.default = _default;
});