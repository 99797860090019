define("personal-portfolio/templates/contact/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CeIO26BW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[0,\"Contact Page\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "personal-portfolio/templates/contact/index.hbs"
    }
  });

  _exports.default = _default;
});