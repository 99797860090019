define("personal-portfolio/templates/posts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y95xKd4B",
    "block": "{\"symbols\":[\"post\",\"@model\"],\"statements\":[[7,\"h1\",true],[8],[0,\"Posts Page\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[5,\"post\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "personal-portfolio/templates/posts/index.hbs"
    }
  });

  _exports.default = _default;
});