define("personal-portfolio/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer>
    <article>
      <section>&copy; 2020 Berdj Abrahamian</section>
    </article>
  </footer>
  */
  {
    id: "r43cWCpI",
    block: "{\"symbols\":[],\"statements\":[[7,\"footer\",true],[8],[0,\"\\n  \"],[7,\"article\",true],[8],[0,\"\\n    \"],[7,\"section\",true],[8],[0,\"\xA9 2020 Berdj Abrahamian\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "personal-portfolio/components/footer.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});