define("personal-portfolio/mirage/factories/post", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    publish() {
      return _faker.default.random.boolean();
    },

    title() {
      return _faker.default.lorem.sentence();
    },

    slug() {
      return _faker.default.lorem.slug();
    },

    author() {
      return _faker.default.name.findName();
    },

    body() {
      return _faker.default.lorem.paragraph();
    },

    featuredImage() {
      return _faker.default.image.dataUri(500, 500);
    },

    createdAt() {
      return _faker.default.date.past();
    },

    updatedAt() {
      return _faker.default.date.recent();
    },

    publishedAt() {
      return _faker.default.date.recent();
    }

  });

  _exports.default = _default;
});