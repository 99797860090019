define("personal-portfolio/components/post", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <article class="postBlock">
    <aside class="postedDate">
      <p><span class="day">{{get @model.postedDate "0"}}</span>
      <span class="month">{{get @model.postedDate "1"}}</span> <span class="year">{{get @model.postedDate "2"}}</span>
      </p>
    </aside>
    <section>
      <img src={{@model.featuredImage}} alt={{@model.title}}>
      <h2>{{@model.id}}</h2>
      <h2>{{@model.publish}}</h2>
      <h1>{{@model.title}}</h1>
      <h3>{{@model.slug}}</h3>
      <h3>{{@model.author}}</h3>
      <h5>{{@model.createdAt}}</h5>
      <h5>{{@model.updatedAt}}</h5>
      <h6>{{@model.publishedAt}}</h6>
      <p>{{@model.body}}</p>
    </section>
    {{yield}}
  </article>
  */
  {
    id: "Jz++rpZ2",
    block: "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[7,\"article\",true],[10,\"class\",\"postBlock\"],[8],[0,\"\\n  \"],[7,\"aside\",true],[10,\"class\",\"postedDate\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[7,\"span\",true],[10,\"class\",\"day\"],[8],[1,[28,\"get\",[[23,1,[\"postedDate\"]],\"0\"],null],false],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"month\"],[8],[1,[28,\"get\",[[23,1,[\"postedDate\"]],\"1\"],null],false],[9],[0,\" \"],[7,\"span\",true],[10,\"class\",\"year\"],[8],[1,[28,\"get\",[[23,1,[\"postedDate\"]],\"2\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"section\",true],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[23,1,[\"featuredImage\"]]],[11,\"alt\",[23,1,[\"title\"]]],[8],[9],[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[23,1,[\"id\"]],false],[9],[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[23,1,[\"publish\"]],false],[9],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n    \"],[7,\"h3\",true],[8],[1,[23,1,[\"slug\"]],false],[9],[0,\"\\n    \"],[7,\"h3\",true],[8],[1,[23,1,[\"author\"]],false],[9],[0,\"\\n    \"],[7,\"h5\",true],[8],[1,[23,1,[\"createdAt\"]],false],[9],[0,\"\\n    \"],[7,\"h5\",true],[8],[1,[23,1,[\"updatedAt\"]],false],[9],[0,\"\\n    \"],[7,\"h6\",true],[8],[1,[23,1,[\"publishedAt\"]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[23,1,[\"body\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "personal-portfolio/components/post.hbs"
    }
  });

  class PostComponent extends _component.default {}

  _exports.default = PostComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PostComponent);
});