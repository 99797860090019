define("personal-portfolio/components/lessons/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <nav>
    <h2>Contents</h2>
    <li><a href="#story-1">Story: How will this project come together</a></li>
    <li><a href="#1">Lesson 1: Getting set up and started</a></li>
    <li><a href="#2">Lesson 2: Lets get the project started</a></li>
    <li><a href="#3">Lesson 3: Cool welcome page, now what</a></li>
    <li><a href="#4">Lesson 4: Website Symantics</a></li>
    <li><a href="#5">Lesson 5: Lets add some content</a></li>
    <li><a href="#6">Lesson 6: Routes and Templates</a></li>
    {{yield}}
  </nav>
  
  */
  {
    id: "PCOyIZ7U",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"nav\",true],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"Contents\"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#story-1\"],[8],[0,\"Story: How will this project come together\"],[9],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#1\"],[8],[0,\"Lesson 1: Getting set up and started\"],[9],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#2\"],[8],[0,\"Lesson 2: Lets get the project started\"],[9],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#3\"],[8],[0,\"Lesson 3: Cool welcome page, now what\"],[9],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#4\"],[8],[0,\"Lesson 4: Website Symantics\"],[9],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#5\"],[8],[0,\"Lesson 5: Lets add some content\"],[9],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#6\"],[8],[0,\"Lesson 6: Routes and Templates\"],[9],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "personal-portfolio/components/lessons/nav.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});