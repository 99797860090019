define("personal-portfolio/routes/projects/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectsIndexRoute extends Ember.Route {
    model() {
      return this.store.findAll('projects/index');
    }

  }

  _exports.default = ProjectsIndexRoute;
});