define("personal-portfolio/routes/projects/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectsShowRoute extends Ember.Route {
    model(params) {
      console.log(params); //this works

      return this.store.findRecord('projects/show', params.project_name);
    }

  }

  _exports.default = ProjectsShowRoute;
});