define("personal-portfolio/components/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <nav>
    <menu>
      <figure>
        <LinkTo @route="index">
          <img src="/assets/images/ba-logo.png" alt="Berdj Abrahamian Personal Portfolio" class="logo" id="logo">
        </LinkTo>
      </figure>
      <li>
        <LinkTo @route="index">Home</LinkTo>
      </li>
      <li>
        <LinkTo @route="projects">Projects</LinkTo>
      </li>
      {{!--
      <li>
        <LinkTo @route="contact">Contact</LinkTo>
      </li>
      <li>
        <LinkTo @route="posts">Posts</LinkTo>
      </li>
      --}}
      <li>
        <LinkTo @route="lessons">Lessons</LinkTo>
      </li>
      <li>
        <LinkTo @route="resume">Resume</LinkTo>
      </li>
    </menu>
  </nav>
  {{yield}}
  */
  {
    id: "uo1zfbEP",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"nav\",true],[8],[0,\"\\n  \"],[7,\"menu\",true],[8],[0,\"\\n    \"],[7,\"figure\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/assets/images/ba-logo.png\"],[10,\"alt\",\"Berdj Abrahamian Personal Portfolio\"],[10,\"class\",\"logo\"],[10,\"id\",\"logo\"],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"projects\"]],{\"statements\":[[0,\"Projects\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"lessons\"]],{\"statements\":[[0,\"Lessons\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"resume\"]],{\"statements\":[[0,\"Resume\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    meta: {
      moduleName: "personal-portfolio/components/nav.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});