define("personal-portfolio/components/header", ["exports", "@glimmer/component", "personal-portfolio/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header>
    <article>
      <section>
        <strong>Berdj Abrahamian</strong>&emsp;|&emsp;E-Commerce Expert&emsp;|&emsp;Full-Stack Developer&emsp;|&emsp;Dev
        Ops Specialist
      </section>
      <section>
        <a href="tel:{{this.phoneNumber}}">{{this.phoneNumber}}</a>
        <a href={{this.githubAddress}}>Github</a>
        <a href="mailto:{{this.email}}">Email</a>
      </section>
    </article>
  </header>
  
  */
  {
    id: "yeq3dIws",
    block: "{\"symbols\":[],\"statements\":[[7,\"header\",true],[8],[0,\"\\n  \"],[7,\"article\",true],[8],[0,\"\\n    \"],[7,\"section\",true],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[0,\"Berdj Abrahamian\"],[9],[0,\"\u2003|\u2003E-Commerce Expert\u2003|\u2003Full-Stack Developer\u2003|\u2003Dev\\n      Ops Specialist\\n    \"],[9],[0,\"\\n    \"],[7,\"section\",true],[8],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[29,[\"tel:\",[23,0,[\"phoneNumber\"]]]]],[8],[1,[23,0,[\"phoneNumber\"]],false],[9],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[23,0,[\"githubAddress\"]]],[8],[0,\"Github\"],[9],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[23,0,[\"email\"]]]]],[8],[0,\"Email\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "personal-portfolio/components/header.hbs"
    }
  });

  class HeaderComponent extends _component.default {
    get githubAddress() {
      return _environment.default.GITHUB + '/' + _environment.default.USERNAME;
    }

    get email() {
      return _environment.default.EMAIL;
    }

    get phoneNumber() {
      return _environment.default.PHONE_NUMBER;
    }

  }

  _exports.default = HeaderComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HeaderComponent);
});