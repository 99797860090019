define("personal-portfolio/serializers/projects", ["exports", "personal-portfolio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectsSerializer extends _application.default {}

  _exports.default = ProjectsSerializer;
});