define("personal-portfolio/templates/projects/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8az9ktJE",
    "block": "{\"symbols\":[\"project\",\"@model\"],\"statements\":[[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"article\",true],[8],[0,\"\\n    \"],[7,\"hgroup\",true],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[23,1,[\"name\"]],false],[0,\"   \"],[4,\"if\",[[23,1,[\"fork\"]]],null,{\"statements\":[[0,\"(\"],[7,\"span\",true],[8],[0,\"Fork\"],[9],[0,\")\"]],\"parameters\":[]},null],[9],[0,\"\\n      \"],[7,\"h5\",true],[8],[1,[23,1,[\"id\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[1,[23,1,[\"description\"]],false],[9],[0,\"\\n\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"projects.show\",[23,1,[\"name\"]]]],{\"statements\":[[0,\"Project Link\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[23,1,[\"html_url\"]]],[8],[0,\"View Project on Github\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "personal-portfolio/templates/projects/index.hbs"
    }
  });

  _exports.default = _default;
});